<template>
  <div>
    <div class="img-container">
      <img v-if="userAvatar" :src="userAvatar" class="rounded border" >
      <b-button class="change" pill variant="info" @click="selectImage" size="sm">Add / Change</b-button>
    </div>
    <div class="avatar-cropper">
      <div v-if="dataUrl" :class="{'avatar-cropper-overlay': true, 'avatar-cropper-overlay-inline': inline}">
        <div class="avatar-cropper-mark" v-if="!inline">
          <a @click="cancel" class="avatar-cropper-close" :title="labels.cancel" href="javascript:;">&times;</a>
        </div>
        <div class="avatar-cropper-container">
          <div class="avatar-cropper-image-container">
            <img :src="dataUrl" alt ref="img"  @load.stop="createCropper" />
          </div>
          <div class="avatar-cropper-footer">
            <button @click.stop.prevent="cancel" class="avatar-cropper-btn" v-text="labels.cancel">Cancel</button>
            <button  @click.stop.prevent="submit" class="avatar-cropper-btn" v-text="labels.submit">Submit</button>
          </div>
        </div>
      </div>
      <input :accept="mimes" class="avatar-cropper-img-input" ref="input" type="file" >
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import 'cropperjs/dist/cropper.css'
import Cropper from 'cropperjs'
import Compressor from 'compressorjs';

export default {
  mixins:[],
  name: "ImagePicker",
  props:{
    value: {
      type: Object,
      default() {
        return {link: null, path: null}
      }
    },
    defaultImg:{
      type:String,
      default: require('@/assets/images/placeholder.png')
    },
    uploadUrl: {
      type: String
    },
    requestMethod: {
      type: String,
      default: 'POST'
    },
    uploadHeaders: {
      type: Object
    },
    uploadFormName: {
      type: String,
      default: 'file'
    },
    uploadFormData: {
      type: Object,
      default() {
        return {}
      }
    },
    outputMime: {
      type: String,
      default: null
    },
    outputQuality: {
      type: Number,
      default: 0.7
    },
    mimes: {
      type: String,
      default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
    },
    withCredentials: {
      type: Boolean,
      default: false
    },
    labels: {
      type: Object,
      default() {
        return {
          submit: 'Save',
          cancel: 'Cancel'
        }
      }
    },
    

    inline: {
      type: Boolean,
      default: false,
    },
    ratio: {
      type: Number,
      default: NaN
    },
    minCroppedWidth: {
      type: Number,
      default: 0
    },
    minCroppedHeight: {
      type: Number,
      default: 0
    },
    maxCroppedWidth: {
      type: Number,
      default: 9999
    },
    maxCroppedHeight: {
      type: Number,
      default: 9999
    },
    outputOptions: {
      type: Object,
      default() {
        return {
          // minWidth: 256,
          // minHeight: 256,
          maxWidth: 1920,
          maxHeight: 2048,
        }
      }
    
    },
    maxWidth: {
      type: Number,
      default: 1920
    },
    maxHeight: {
      type: Number,
      default: 2048
    },
  },
  data() {
    return {
      cropper: undefined,
      dataUrl: undefined,
      filename: undefined,
      change:false,
      // userAvatar: this.value.link || this.defaultImg,
      cropperOptions: {
        aspectRatio: this.ratio,
        viewMode: 1,
        movable: false,
        zoomable: false,
        crop: this.crop, // is used to set max limit of image size
      }
    }
  },
  methods: {
    destroy() {
      this.cropper.destroy()
      this.$refs.input.value = ''
      this.dataUrl = undefined
    },
    submit() {
      this.$emit('submit')
      if (this.uploadUrl) {
        this.uploadImageManually()
      } else {
        this.uploadImageAutomatic()
      }

      this.destroy()
    },
    cancel(){
      this.$emit('cancel')
      this.destroy();
    },
    pickImage(e) {
      this.$refs.input.click()
      e.preventDefault()
      e.stopPropagation()
    },
    createCropper() {
      this.cropper = new Cropper(this.$refs.img, this.cropperOptions)
    },
    getFileName(){
      return this.filename;
    },
    uploadImageAutomatic(){
      let filename = this.getFileName()
      let that = this
      this.cropper.getCroppedCanvas(this.outputOptions).toBlob((blob) => {

        var img_type = blob.type;
  
        new Compressor(blob, {
          strict: true,
          // checkOrientation: true,
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          minWidth: 0,
          minHeight: 0,
          width: undefined,
          height: undefined,
          quality: this.outputQuality,
          mimeType: img_type,
          convertSize: 2000,  
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            that.blobToBase64(result, function (data) {
              const fd = new FormData();
              fd.append('filename', filename)
              fd.append('image', 'data:'+img_type+';base64,'+data)
              fd.append('image_type', 'avatar')
    
              that.userAvatar = 'data:'+img_type+';base64,'+data
              // that.$emit('saveImage', fd);
              
              that.UploadSingleImage(fd)
            })
          },
          error(err) {
            console.log(err.message);
          },
        });

      }, that.outputMime, that.outputQuality);

    },
    UploadSingleImage(data) {
      // console.log('img data:', data);
      // this.passport_info.passport_image = data;
      // this.passport_info.passport_image = data.get('image');
    
      let that = this
      this.$swal({
        title: 'Updating image...' ,
        // html:'<img class="img-sa" src="'+iData+'">',
        allowOutsideClick: true,
        // timer: 2000
      });
      this.$swal.showLoading();

      this.$axios.$post('/company/upload', data).then(response => {
        that.$swal.close()
        console.log('response', response);
        if(response.success){
          that.$emit('input', response);

          that.$swal({
            title: 'Image updated!',
            type: 'success',
            allowOutsideClick: false,
          })
        } else {
          that.$emit('input', data.get('image') );
          that.$swal({
            title: 'Opps! Something Happened Wrong',
            type: 'error',
            allowOutsideClick: true,
          })
        }
      }, err => {
        that.$swal.close()        
        that.$emit('input', data.get('image') );
        that.$swal({
          title: 'Opps! Something Happened Wrong: ' + err.message,
          type: 'error',
          allowOutsideClick: true
        })
      })
    },
    uploadImageManually() {
      let that = this
      this.cropper.getCroppedCanvas(this.outputOptions).toBlob((blob) => {

        var img_type = blob.type;
        let filename = this.getFileName()
  
        new Compressor(blob, {
          strict: true,
          // checkOrientation: true,
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          minWidth: 0,
          minHeight: 0,
          width: undefined,
          height: undefined,
          quality: this.outputQuality,
          mimeType: img_type,
          convertSize: 2000,  
          // The compression process is asynchronous,
          // which means you have to access the `result` in the `success` hook function.
          success(result) {
            that.blobToBase64(result, function (data) {
              const form = new FormData();
              form.append('filename', filename)
              form.append('image', 'data:'+img_type+';base64,'+data)
              form.append('image_type', 'avatar')
    
              that.userAvatar = 'data:'+img_type+';base64,'+data

              let xhr = new XMLHttpRequest()
              that.$emit('uploading', form, xhr)
              xhr.open(that.requestMethod, that.uploadUrl, true)
              for (let header in that.uploadHeaders) {
                xhr.setRequestHeader(header, that.uploadHeaders[header])
              }
              xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                  let response = ''
                  try {
                    response = JSON.parse(xhr.responseText)
                  } catch (err) {
                    response = xhr.responseText
                  }
                  that.$emit('completed', response, form, xhr)
                  if ([200, 201, 204].indexOf(xhr.status) > -1) {
                    that.$emit('uploaded', response, form, xhr);
                    console.log('llllllllllllllllllllllll', response);
                    that.$emit('input', response.data);
                    // that.handleUploaded(response);
                  } else {
                    that.$emit('error', 'Image upload fail.', 'upload', xhr)
                  }
                }
              }
              xhr.send(form)
            })
          },
          error(err) {
            console.log(err.message);
          },
        });

      }, that.outputMime, that.outputQuality);
      
    },
    crop(event) {
      var width = event.detail.width;
      var height = event.detail.height;
  
      if (
        width < this.minCroppedWidth
        || height < this.minCroppedHeight
        || width > this.maxCroppedWidth
        || height > this.maxCroppedHeight
      ) {
        this.cropper.setData({
          width: Math.max(this.minCroppedWidth, Math.min(this.maxCroppedWidth, width)),
          height: Math.max(this.minCroppedHeight, Math.min(this.maxCroppedHeight, height)),
        });
      }
      // console.log(JSON.stringify(this.cropper.getData(true)));
    },
    handleUploaded(resp) {
      this.value.link = resp.relative_url;
    },
    blobToBase64(blob, callback) {
        var reader = new FileReader();
        reader.onload = function() {
            var dataUrl = reader.result;
            var base64 = dataUrl.split(',')[1];
            callback(base64);
        };
        reader.readAsDataURL(blob);
    },
    makeFilename(filename){
      let extension = filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2);
      let onlyName = filename.replace("."+extension, "")
      onlyName = onlyName.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('_');
      return onlyName+"."+extension
    },
    cancelledChange(){
      this.change = false
    },
    selectImage(e){
      this.change = true
      this.pickImage(e)
    }
  },
  computed: {
    userAvatar: {
      get(){
        return this.value.link || this.defaultImg || require('@/assets/images/placeholder.png');
      },
      set(val){} 
    },
  },
  watch:{
    // value: {
    //   // the callback will be called immediately after the start of the observation
    //   immediate: true,
    //   handler (val, oldVal) {
    //     if(_.isEmpty(val)){
    //       console.log('watched called')
    //       this.userAvatar = this.value.link || this.defaultImg || require('@/assets/images/placeholder.png')
    //     }
    //   }
    // }
  },
  mounted() {
    // listen for input file changes
    let fileInput = this.$refs.input
    fileInput.addEventListener('change', () => {
      if (fileInput.files != null && fileInput.files[0] != null) {
        let correctType = this.mimes.split(', ').find(m => m === fileInput.files[0].type);
        if (!correctType) {
          this.$emit('error', 'File type not correct.', 'user');
          return;
        }
        let reader = new FileReader()
        reader.onload = e => {
          this.dataUrl = e.target.result
        }
        reader.readAsDataURL(fileInput.files[0])
        this.filename = fileInput.files[0].name || 'unknown'
        this.mimeType = this.mimeType || fileInput.files[0].type
        this.$emit('changed', fileInput.files[0], reader)
      }
    })
  }

}
</script>

<style lang="scss" scoped>
.img-container {
  position: relative;
  width: 100%;
  max-width: 100px;
  display: inline-block;
  &:hover {
    .btn {
      opacity: 1;
    }
  }
  img {
    width: 100%;
    height: auto;
  }
  .btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0;
    &:hover {
      background-color: black;
    }
  }
}

.avatar-cropper {
  .avatar-cropper-overlay {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }
  .avatar-cropper-overlay-inline{
    position: initial;
  }
  .avatar-cropper-img-input {
    display: none;
  }
  .avatar-cropper-close {
    float: right;
    padding: 20px;
    font-size: 3rem;
    color: #fff;
    font-weight: 100;
    text-shadow: 0px 1px rgba(40, 40, 40, 0.3);
  }
  .avatar-cropper-mark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
  }
  .avatar-cropper-container {
    background: #fff;
    z-index: 999;
    box-shadow: 1px 1px 5px rgba(100, 100, 100, 0.14);
    .avatar-cropper-image-container {
      position: relative;
      max-width: 400px;
      height: 300px;
    }
    img {
      max-width: 100%;
      height: 100%;
    }
    .avatar-cropper-footer {
      display: flex;
      align-items: stretch;
      align-content: stretch;
      justify-content: space-between;
      .avatar-cropper-btn {
        width: 50%;
        padding: 15px 0;
        cursor: pointer;
        border: none;
        background: transparent;
        outline: none;
        &:hover {
          background-color: #2aabd2;
          color: #fff;
        }
      }
    }
  }
}
</style>
