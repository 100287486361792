<template>
  <section class="home-section section-hero overlay bg-image" :style="{ backgroundImage: 'url(' + require('@/assets/images/peace_bg.jpg') + ')' }" id="home-section">

    <div class="container">
      <div class="row align-items-center justify-content-center">
        <div class="col-md-6">
          <!-- <image-picker v-model="img_link">
          </image-picker> -->
          <div class="mb-5 text-center">
            <h1 v-if="!authenticated" class="page-title">Login</h1>
            <h1 v-if="authenticated" class="page-title">Logged in!</h1>
          </div>
          <div class="mb-5 login-container">
            <Login v-if="!authenticated" />
          </div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import Login from '~/components/auth/Login';
import ImagePicker from "@/components/media/ImagePicker";
export default {
  layout: 'main',
  // middleware: 'auth',
  components:{Login, ImagePicker},
  data(){
    return {
      img_link: {link: require('@/assets/images/peace_bg.jpg'), path: null},
      partyName: "",
    }
  },
  head() {
    return {
      title: 'Login | SteveJobs.com.au',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'SteveJobs.com.au is a job searching and employer hosting platform'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped >

</style>
