<template>
  <form id="app" @submit.prevent="login" >
    <div class="text-danger text-center" v-if="lv_errors.active">{{ lv_errors.active[0] }}</div>
    <div class="text-danger text-center" v-if="lv_errors.messages">{{ lv_errors.messages[0] }}</div>
    <!-- <label for="uname"><b>Username</b></label> -->
    <input type="text" placeholder="Email address" id="uname" :class="{'is-invalid' : lv_errors.email }" v-model="form.email" class="login-input" required>
    <div class="invalid-feedback" v-if="lv_errors.email">{{ lv_errors.email[0] }}</div>
    <!-- <label for="psw"><b>Password</b></label> -->
    <input type="password" placeholder="Enter password" v-on:keyup.enter="login()" id="psw" :class="{'is-invalid' : lv_errors.password }" v-model="form.password" class="login-input" required>
    <div class="invalid-feedback" v-if="lv_errors.password">{{ lv_errors.password[0] }}</div>
    <button type="submit" class="login-button">Login</button>
    <p class="text-center text-white-50">Don't have an account? <nuxt-link to="/register">Register</nuxt-link></p>
    <div v-if="lv_errors.messages" class="text-center"><nuxt-link to="/forgot-password">Forgot Password?</nuxt-link></div>

  </form>
</template>

<script>
export default {
  middleware : 'guest',
  data(){
    return{
      form:{
        email: '',
        password: '',
        active: '1'
      }
    }
  },
  methods:{
    submit(){
      let that = this
      this.$axios.$post('/company/login', that.form)
      .then(function (response) {
        if(response.success){
          let token  = response.access_token
          let expiery = response.expires_in
          that.$auth.$storage.setCookie('token', token)
          that.$store.dispatch('setToken', { token, expiery});
          that.$router.push({ name : 'index'})
        }
      })
      .catch(function (error) {
          console.log(error);
      });
    },

    login(){
      let that = this
      this.$auth.login({ data: that.form })
        // that.$router.push({ name : 'user-profile'})
        .then(function (response) {
          if(response.data.success){
            that.$router.push({ name : 'my-profile'})
          }
        })
    },
  }
}
</script>

<style lang="scss" scoped >

</style>
